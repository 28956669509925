<template>
  <section class="d-flex flex-column flex-grow-1 justify-center pa-4 pa-sm-8 pa-lg-16 grey lighten-2" :class="xHeight">
    
    <header class="pa-sm-8 pa-lg-16 grey--text text--darken-4">
      <p class="text-h6 text-md-h5 text-lg-h4 text-uppercase">Selected keynotes and publications</p>
      <h1 class="text-h3 text-md-h2 text-lg-h1 font-weight-bold">From around the world</h1>
    </header>


    <p class="pt-8 pt-sm-0 px-4 px-sm-8 px-lg-16 text-center">
      <v-btn icon light @click="prev"><v-icon large>mdi-chevron-left</v-icon></v-btn>
      <v-btn icon light @click="next"><v-icon large>mdi-chevron-right</v-icon></v-btn>
    </p>

    <v-window v-model="slider" show-arrows class="pt-0 px-sm-8 px-lg-16">
      <template v-for="(c, i) in chunk(items, 6)">
        <v-window-item :key="`w${i}`">
          <v-row>
            <template v-for="(item, j) in c">
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" :key="`a${j}`">
                <v-card light tile class="fill-height" :href="item.href" target="_blank">
                  <v-img :aspect-ratio="16/9" eager :src="`/images/${item.image}`"></v-img>
                  <v-card-title v-html="item.title"></v-card-title>
                  <v-card-subtitle class="caption font-weight-bold">{{ item.date }}</v-card-subtitle>
                  <v-card-text>{{ item.text }}</v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-window-item>
      </template>
    </v-window>

  </section>
</template>

<script>
export default {
  name: "Knowledge",
  data() {
    return {
      show: false,
      slider: 0,
      items: [
        {
          image: 'ceraweek2.jpg',
          date: 'March 2025',
          title: 'Digital 5.0: Improving operational performance',
          text: 'How is AI revolutionizing geothermal and oil and gas operations? Listen to AlphaX COO Aruna Viswanathan discuss technology\'s role in driving efficiency and enabling the energy transition.',
          href: 'https://events.tie.org/InvestingInTheAgeofAI'
        },
        {
          image: 'tie.jpg',
          date: 'January 2025',
          title: 'Investing in the age of AI',
          text: 'Join TiE Houston\'s premier AI event of 2025 where visionary leaders take the stage before an audience of Houston\'s leading energy and healthcare companies, investor, and technology enterpreneurs.',
          href: 'https://events.tie.org/InvestingInTheAgeofAI'
        },
        {
          image: 'image.jpg',
          date: 'August 2024',
          title: 'IMAGE 2024',
          text: 'AlphaX CEO Sammy Haroon will be at the #IMAGE2024 conference in the Digitalization Pavilion discussing the groundbreaking impact of Unified Asset Intelligence (UAI), powered by AlphaX Sky and AWS, on the oil and gas industry.',
          href: 'https://www.imageevent.org/program/technical-program/pavilions/digitalization-pavilion'
        },
        {
          image: 'superdug.jpg',
          date: 'May 2024',
          title: 'SUPERDUG 2024',
          text: 'Sammy Haroon, CEO of AlphaX and Mo Saadat, Managing Director of Upstream Portfolio, Accenture speak on the use of #AI for #AssetManagement at the 2024 SuperDUG conference AI in the Oilfield Workshop.',
          href: 'https://www.hartenergy.com/events/super-dug-2024/Agenda#tab-3'
        },
        {
          image: 'edot20.jpg',
          date: 'April 2024',
          title: 'Energy 2.0 The Unconference',
          text: 'AlphaX COO Aruna Viswanathan spoke Ally Energy\'s Energy 2.0 "Unconference" on the role of #AI in a lower carbon future.',
          href: 'https://www.energy2dot0.com/'
        },
        {
          image: 'ceraweek.jpg',
          date: 'March 2024',
          title: 'CERAWeek 2024',
          text: 'AlphaX Sky demo was showcased along with other #AI solutions at the Microsoft Partner House at Agora at CERAWeek 2024.',
          href: 'https://partnerinnovation.microsoft.com/events/ceraweek-2024/'
        },

        {
          image: 'executive_oil.jpg',
          date: 'November 2023',
          title: 'Executive Oil',
          text: 'Sammy Haroon, CEO of AlphaX will be speaking on a panel discussion entitled "Tech Talk: AI, Automation and Boots on the Ground", to discuss case studies for where AI is making an impact in the Permian and elsewhere.',
          href: 'https://www.hartenergy.com/events/executive-oil-conference-2023/'
        },
        {
          image: 'diversity.jpg',
          date: 'October 2023',
          title: 'Rice Business DEI Conference',
          text: 'AlphaX\'s Aruna Viswanathan\'s fireside chat with Michael Sklar at Rice University explored fairness, accuracy and explainability in AI and how these relate to DEI efforts.',
          href: 'https://business.rice.edu/diversity-equity-and-inclusion-conference'
        },
        {
          image: 'goldman.jpg',
          date: 'June 2023',
          title: 'Leveraging "AI" in small businesses',
          text: 'AlphaX joined as a panelist on an online webinar hosted by Goldman Sachs on leveraging artificial intelligence in small businesses.',
          href: 'https://www.linkedin.com/company/goldman-sachs/'
        },
        {
          image: 'spe_pbs.png',
          date: 'April 2023',
          title: 'Unlocking Permian\'s potential through Sky',
          text: 'Learn how Lario performed rapid sensitivity analysis in a matter of minutes instead of days using Sky, assessing the impact of different cross-functional production and reservoir optimization variables.',
          // href: 'https://spepermianbasin.ticketspice.com/spe-permian-basin-april-section-meeting'
        },
        {
          image: 'techrodeo.jpg',
          date: 'February 2023',
          title: 'AlphaX to participate in TechRodeo panel on the future of AI in education',
          text: 'AlphaX will be joining a diverse panel of corporate and academic experts to share an employer’s perspective on skills AI driven companies need and how workers may be engaging with AI in the future.',
          href: 'https://houstontechrodeo.com/'
        },
        {
          image: 'aapg.png',
          date: "January 2023",
          title: "AAPG Presentation",
          text: 'AlphaX Decision Sciences Chief Creative Destruction Officer aka CEO (Sammy Haroon) discusses #4IR driven #oilandgas future with American Association of Petroleum Geologists (AAPG)',
          href: "https://www.dropbox.com/s/b6pxppjuxqyl90z/AAPGJan2023.pdf",
        },
        {
          image: 'meosgeo.jpg',
          date: 'February 2023',
          title: 'AlphaX to join panel at MEOS-GEO 2023',
          text: 'AlphaX will be joining an all-star panel on the Fourth Industrial Revolution at #MEOSGEO, including Aramco, Halliburton, Chevron, Microsoft and Schlumberger Oil Field Services.',
          href: 'https://meos-geo.com/'
        },
        {
          image: 'nmcerg.jpg',
          date: 'January 2023',
          title: 'AlphaX joins Clean Energy Resilience and Growth Cluster',
          text: 'AlphaX has recently joined Department of Energy\'s New Mexico Clean Energy Resilience and Growth Cluster.',
          href: 'https://arrowheadcenter.org/program/nmcerg/'
        },
        {
          image: 'aiiot.png',
          date: 'November 2022',
          title: 'Panel discussion at the AI Summit Austin, 2022',
          text: 'AlphaX participated in a panel dicussion on IoT and AI Driving Innovation for Energy, Oil and Gas, the importance of data, constraints of AI in our business and how we are overcoming them.',
          href: 'https://austin.appliedintelligence.live/ai-summit-austin'
        },
        {
          image: 'etvf.png',
          date: 'September 2022',
          title: 'AlphaX participating in ETVF 2022',
          text: 'Energy Tech Venture Forum, hosted by The Rice Alliance for Technology and Entrepreneurship, is the premier energy tech venture capital conference to connect energy innovators, investors, corporates, and the energy ecosystem.',
          href: 'https://alliance.rice.edu/sites/alliance/files/2022-09/Company_Directory_2022_ETVF.pdf'
        },
        {
          image: 'hart_energy.jpg',
          date: 'July 2022',
          title: 'HART Energy features AlphaX Software',
          text: 'AI-driven software for unconventional assets helps operators finetune completion strategies and democratizes access to accurate production forecasting.',
          href: 'https://www.dropbox.com/s/9mj3y7esqbyhvwn/AlphaXHartArticle.pdf'
        },
        {
          image: 'ai_future.jpg',
          date: 'April 2022',
          title: 'A.I. & The Future of Houston Conference',
          text: 'The AI and the Future of Houston Conference aims to explore the technological advances and innovation brought by AI.',
          href: 'https://www.eventbrite.com/e/ai-the-future-of-houston-conference-registration-308450562937'
        },
        {
          image: 'spe.png',
          date: 'July 2021',
          title: 'Data Science Convention',
          text: 'Fulfilling the Potential of the Digital Transformation to Navigate the Energy Transition - by Dr. Ram Shenoy',
          href: 'https://www.spegcs.org/events/6018/'
        },
        {
          image: 'substack.webp',
          date: 'May 2021',
          title: 'Future of Energy (Podcast)',
          text: 'How to build a successful business seeing the energy transition as an opportunity',
          href: 'https://mikatienhaara.substack.com/p/s1e10-future-of-energy-how-to-build'
        },
        {
          image: 'bend.jpg',
          date: "May 2021",
          title: "Applications of AI",
          text: "The Fort Bend Future Initiative's Artificial Intelligence Series, “Applications of AI?” How experts use AI in their businesses.",
          href: "https://m.facebook.com/events/462958834978635",
        },
        {
          image: 'otc.jpg',
          date: "Nov 2020",
          title: "OTC Live Panel",
          text: "\"AI Accelerated Creative Destruction: O&G's Pivotal 20's\" talk on Panel, OTC Live - Innovation in Upstream Oil and Gas: From Development to Scale-Up Implementation",
          href: "https://www.youtube.com/watch?v=5MhMoGXT8Ic",
        },
        {
          image: 'spe.png',
          date: "Jun 2020",
          title: "SPE AAPG Webinar",
          text: "Reinvention in Times of Disruption",
          href: "https://www.spegcs.org/events/5699/",
        },
        {
          image: 'otc.jpg',
          date: "May 2020",
          title: "OTC Paper",
          text: "Acceleration of 4IR Driven Digital Transformation Through Open Source: Methods and Parallel Industries Knowledge Reapplication in the Field",
          href: "https://onepetro.org/OTCONF/proceedings-abstract/20OTC/4-20OTC/D041S055R004/107475",
        },
        {
          image: 'chemindix.png',
          date: "Jun 2019",
          title: "Conference and Exhibition on Chemistry in Industry (ChemIndix)",
          text: "Role of Artificial Intelligence in the World of Molecules",
          href: "https://chemindix.org/panelist-and-keynote-speakers/",
        },
        {
          image: 'spe.png',
          date: "Apr 2019",
          title: "SPE Gulf Coast Section",
          text: "You Want the Truth? Adapting O&G Organizations to Make Digital Transformation Successful",
          href: "https://www.spegcs.org/events/4077/",
        },
        {
          image: 'meos.png',
          date: "Mar 2019",
          title: "Middle East Oil Conference (MEOS) 2019",
          text: '"Accelerating 4IR in the O&G Sector" Presentation and Panel "4th Industrial Revolution (IR 4.0)"',
          href: "http://meos-expo.com/site/wp-content/uploads/MEOS-2019-conference-preview.pdf",
        },
        {
          image: 'ceraweek.png',
          date: "Mar 2019",
          title: "CERA Week 2019",
          text: 'Agora Presentation, "AI Comes of Age" Sammy Haroon, Founder and CEO, AlphaX Decision Sciences speaks about how AI enables businesses to make faster, smarter decisions with pragmatic processes, sharing their experiences and what they have learned from customers about what works and doesn\'t work',
          href: "https://ondemand.ceraweek.com/detail/video/6020601387001/ai-comes-of-age",
        },
        {
          image: 'adipec.png',
          date: "Nov 2018",
          title: "Abu Dhabi Int’l Petroleum Exhibition Conference (ADIPEC) 2018",
          text: "Big Data-Driven Advanced Analytics: Application of Convolutional and Deep Neural Networks for GPU Based Seismic Interpretations",
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/2-18ADIP/D021S035R003/213071",
        },
        {
          image: 'adipec.png',
          date: "Nov 2018",
          title: "Abu Dhabi Int’l Petroleum Exhibition Conference (ADIPEC) 2018",
          text: "From Insight to Foresight: Knowing how to Apply Artificial Intelligence in the Oil and Gas Industry",
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/3-18ADIP/D031S066R003/212948",
        },
        {
          image: 'usrcc.jpeg',
          date: "Nov 2018",
          title: 'USRCC "3rd Technology and Innovation Conference" Houston',
          text: "From Insight to Foresight: Knowing how to Apply Artificial Intelligence in the Oil and Gas Industry",
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/3-18ADIP/D031S066R003/212948",
        },
        {
          image: 'aramco.png',
          date: "Sep 2018",
          title: "iQuest Presentation to SAO EXPEC ARC",
          text: "Application of Deep Neural Networks for GPU Based Seismic Interpretations",
          href: "https://www.aramco.com/-/media/publications/dimensions/2018-dimi-winter.pdf",
        },
        {
          image: 'aramco.png',
          date: "Sep 2018",
          title: "iQuest Presentation to SAO EXPEC ARC",
          text: "4IR Based Data Residency and Privacy Solutions, and Un-hackable Analytics Cloud and Technology Infrastructure",
          href: "https://www.aramco.com/-/media/publications/dimensions/2018-dimi-winter.pdf",
        },
        {
          image: 'aapg.png',
          date: "May 2018",
          title: "AAPG Presentation",
          text: 'Abstract "From Insight to Foresight"',
          href: "https://onepetro.org/SPEADIP/proceedings-abstract/18ADIP/3-18ADIP/D031S066R003/212948",
        },
        {
          image: 'rice.png',
          date: "May 2018",
          title: "Rice Alliance OTC Event",
          text: "Won Most Promising Company from Elevator Pitch",
          href: "https://jpt.spe.org/university-group-lists-most-promising-oil-gas-startups-year",
        },
      ],
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    chunk(array) {
      const chunk_size = this.getChunk()
      return Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size))
    },
    getChunk() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 1
        case 'sm': return 3
        case 'md': return 4
        case 'lg': return 4
        case 'xl': return 6
        default: return 6
      }
    },
    next() {
      this.slider = this.slider + 1 === this.items.length ? 0 : this.slider + 1;
    },
    prev() {
      this.slider = this.slider - 1 < 0 ? this.items.length - 1 : this.slider - 1;
    },
  }
};
</script>